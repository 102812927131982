import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col,Card} from 'react-bootstrap'
import SEO from "../components/seo"


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Mobile&Desktop" />
        <div  className="homeAuto">
            <Image alt="home automation" filename="mobile-desktop.png"  />
                <div className="mobileImage">
                    <h1 style={{fontWeight:"bold"}} className="freehead">MOBILE & DESKTOP SOLUTIONS</h1>
                    <h5 className="freeheadpara">Today’s competitive market demands more. With our customized</h5>
                    <p  className="freeheadpara" style={{fontSize:"20px"}}>mobile and desktop software development, you can deliver more!</p>
                </div>
        </div>
        <Container>
            <Row className="aboutrow">
                <Col sm={12} lg={6}>
                    <Image alt="auto" filename="mob_app.png"  />
                </Col>
                <Col sm={12} lg={6} className="manuPara1">
                    <p>For your app to succeed, you need a smart business strategy, a killer UI/UX design, and a talented mobile development team. Good news – you’ve come to the right source. In the glut of ho-hum mobile apps, we don’t settle for good. Innovative mobile business strategies. Beautiful user experience designs. Powerful agile app development. Stand-out launch marketing. Comprehensive product life cycle management. That’s the mobile-first metaverse we’re creating.</p>
                    <p>Our team of skilled iOS & Android specialists will partner with you to develop your idea into a feature-rich application. Our creative designers focus on making the app interactive and ensure high user engagement with intuitive user interface.</p>
                </Col>
            </Row>
            <Row style={{marginLeft:"100px"}}>
                <Col sm={12} lg={4}><Image alt="ios" filename="ap1.png"  style={{width : "136px"}} /></Col>
                <Col sm={12} lg={4}><Image alt="android" filename="ap2.png"  style={{width : "136px"}}/></Col>
                <Col sm={12} lg={4}><Image alt="windows" filename="ap3.png" style={{width : "136px"}} /></Col>
            </Row>

            <Row className="aboutrow">
                <Col sm={12} lg={6} className="manuPara1">
                    <p>Next-generation outfit solutions in health, security, retail and more are coming instantly. We’ve been on the cutting edge of wearable interfaces for years. If it’s wearable electronics — it’s mobile. That means it needs apps to bring the power of smart glasses and watches, as well as fit bands and yet-to-be-made devices to users. Think malleable LED screens, mobile biometrics, holographic designing, and facial and eye recognizance.</p>
                    <p>Our mobile backend-as-a-service offers you the capabilities you need to get to market fast, with the freedom and flexibility you expect. We can handle your enterprise CRM integration, consumer integration, push notifications, storage, security, mobile payments, GPS, and much more. If you need user management, seamless connections with APIs, cloud storage or integration with social networks – we’ve been there, done that, ready to do it again.</p>
                </Col>
                <Col sm={12} lg={6}><Image alt="wear" filename="wear.png"  /></Col>
            </Row>

            <Row>
                <h3 className="manuh3">Our work speaks louder than words :</h3>
                <p style={{marginLeft:"20px"}} className="manuPara1">Are you sick and tired of incessant amendments yet your app is not turning out the way you wanted it to be? Worry no more as we will make you an app just the way you like it to be. We pay special consideration to our client’s requirements, design and develop app to their liking, because for us, client satisfaction is the eventual prize that we could take.</p>
                <Col sm={12} lg={3}>
                    <Card className="softCard"> 
                        <Image alt="my1" filename="my1.png" style={{width : "136px"}} />
                    </Card>
                </Col>
                
                <Col sm={12} lg={3}>
                    <Card className="softCard"> 
                        <Image alt="my2" filename="my2.png" style={{width : "136px"}} />
                    </Card>
                </Col>
                <Col sm={12} lg={3}> 
                    <Card className="softCard"> 
                        <Image alt="my3" filename="my3.png" style={{width : "136px", align:"center"}} />
                    </Card>
                </Col>
                <Col sm={12} lg={3}>
                    <Card className="softCard"> 
                        <Image alt="my4" filename="mybook-pro.png" style={{width : "136px"}}  />
                    </Card>    
                </Col>
            </Row>
        </Container>

        <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">Ready to get your design off the ground? Contact Trace Width Technology Solutions today!</div>
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

